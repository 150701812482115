import BN from 'bn.js';

export const ADMIN_FEE_IN_BASIS_POINTS = 500;

export const BIG_NUMBER = Number(new BN(0).notn(32).toString());
export const BIG_NUBMER_STRING = new BN(0).notn(256).toString();

export const COUNTS_ON_PAGE = 24;

export const NFTFI_API = 'https://api.nftfi.com';

export const OPENSEA_API = 'https://api.opensea.io/api/v1';

export const TOKENOMICS_API = 'https://tokenomics.syncbond.com';

export const COUNTS_CONFIRMATIONS_REQUIRED = 3;
