import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
  },
}));

const StatsIcon = ({ viewBox, width, height, color, ...rest }) => {
  const classes = useStyles();
  return (
    <SvgIcon width='24px' height='44px' viewBox='0 0 24 24' className={classes.root} {...rest}>
      <path d='M10 20h4V4h-4v16zm-6 0h4v-8H4v8zM16 9v11h4V9h-4z'></path>
    </SvgIcon>
  );
};

export default StatsIcon;
