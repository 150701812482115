import PAGES from './pages';
import EducatorsIcon from 'components/Icons/EducatorsIcon';
import FilterIcon from 'components/Icons/FilterIcon';
import GovernIcon from 'components/Icons/GovernIcon';
import CreateIcon from 'components/Icons/CreateIcon';
import ShareIcon from 'components/Icons/ShareIcon';
import StorefrontIcon from 'components/Icons/StorefrontIcon';
import AboutIcon from 'components/Icons/AboutIcon';
import StatsIcon from 'components/Icons/StatsIcon';

const TOP_BAR_MENUS = [
  {
    id: 'GOVERN',
    icon: <GovernIcon />,
    url: PAGES.GOVERN.url,
    text: PAGES.GOVERN.title,
  },
  {
    id: 'CREATE',
    icon: <CreateIcon />,
    url: PAGES.CREATE.url,
    text: PAGES.CREATE.title,
  },
  {
    id: 'BORROW',
    icon: <FilterIcon />,
    url: PAGES.BORROW.url,
    text: PAGES.BORROW.title,
    subMenus: [
      {
        label: 'COLLATERAL',
        url: '/',
      },
      {
        label: 'LOANS',
        url: '/borrow/loans',
      },
      {
        label: 'OFFERS',
        url: '/borrow/offers',
      },
    ],
  },
  {
    id: 'LEND',
    icon: <ShareIcon />,
    url: PAGES.LEND.url,
    text: PAGES.LEND.title,
    subMenus: [
      {
        label: 'ASSETS',
        url: '/lend',
      },
      {
        label: 'LOANS',
        url: '/lend/loans',
      },
      {
        label: 'OFFERS',
        url: '/lend/offers',
      },
    ],
  },
  {
    id: 'ACCOUNT',
    icon: <EducatorsIcon />,
    url: PAGES.ACCOUNT.url,
    text: PAGES.ACCOUNT.title,
  },
  {
    id: 'MARKETPLACE',
    icon: <StorefrontIcon />,
    url: PAGES.MARKETPLACE.url,
    text: PAGES.MARKETPLACE.title,
  },
  {
    id: 'ABOUT',
    icon: <AboutIcon />,
    url: PAGES.ABOUT.url,
    text: PAGES.ABOUT.title,
    subMenus: [
      {
        label: 'FAQ',
        url: 'https://sync-network.gitbook.io/',
      },
      {
        label: 'GUIDE',
        url: 'https://sync-network.gitbook.io/sync-network/introduction',
      },
      {
        label: 'SYNC CONTRACT',
        url: 'https://etherscan.io/address/0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32',
      },

      {
        label: 'Blockfolio',
        url: 'https://blockfolio.com/coin/SYNC_2',
      },
      {
        label: 'LiveCoinWatch',
        url: 'https://www.livecoinwatch.com/price/SyncNetwork-SYNC',
      },
      {
        label: 'UNISWAP',
        url: 'https://uniswap.info/token/0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32',
      },
      {
        label: 'Whitepaper',
        url: 'https://pdfhost.io/v/eJxdO5.YU_SYNC_NETWORK_Whitepaper_v20.pdf',
      },
      {
        label: 'Partnership Request',
        url: 'https://forms.gle/iZDNn3qSoHwTYvSw7',
      },
    ],
  },
  {
    id: 'STATS',
    icon: <StatsIcon />,
    url: PAGES.STATS.url,
    text: PAGES.STATS.title,
  },
];

const MOBILE_MENUS = [
  {
    id: 'CREATE',
    icon: <CreateIcon />,
    url: PAGES.CREATE.url,
    text: PAGES.CREATE.title,
  },
  {
    id: 'BORROW',
    icon: <FilterIcon />,
    url: PAGES.BORROW.url,
    text: PAGES.BORROW.title,
  },
  {
    text: 'COLLATERAL',
    url: '/',
  },
  {
    text: 'LOANS',
    url: '/borrow/loans',
  },
  {
    text: 'OFFERS',
    url: '/borrow/offers',
  },
  {
    id: 'LEND',
    icon: <ShareIcon />,
    url: PAGES.LEND.url,
    text: PAGES.LEND.title,
  },
  {
    text: 'ASSETS',
    url: '/lend',
  },
  {
    text: 'LOANS',
    url: '/lend/loans',
  },
  {
    text: 'OFFERS',
    url: '/lend/offers',
  },
  {
    id: 'ACCOUNT',
    icon: <EducatorsIcon />,
    url: PAGES.ACCOUNT.url,
    text: PAGES.ACCOUNT.title,
  },
  {
    id: 'STATS',
    icon: <StatsIcon />,
    url: PAGES.STATS.url,
    text: PAGES.STATS.title,
  },
];

export { TOP_BAR_MENUS, MOBILE_MENUS };
