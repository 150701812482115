const PAGES = {
  COMMUNITIY: {
    title: 'Community',
    url: 'https://discord.gg/pnzgt8E2Kq',
  },
  STATS: {
    title: 'Stats',
    url: '/stats',
  },
  LEND: {
    title: 'Lend',
    url: '/lend',
  },
  BORROW: {
    title: 'Borrow',
    url: '/',
  },
  ACCOUNT: {
    title: 'Account',
    url: '/account',
  },
  FAQ: {
    title: 'FAQ',
    url: 'https://medium.com/@nftfi/nftfi-com-f9ecf4ab1e7d',
  },
  ASSETS: {
    title: 'Asset',
    url: '/assets',
  },
  UNLOCK: {
    title: 'Unlock',
    url: '/unlock',
  },
  GOVERN: {
    title: 'GOVERN',
    url: 'https://snapshot.page/#/cryptobond.eth',
  },
  CREATE: {
    title: 'CREATE',
    url: 'https://app.syncbond.com/',
  },
  MARKETPLACE: {
    title: 'MARKETPLACE',
    url: 'https://app.rarible.com/collection/0xc6c11f32d3ccc3beaac68793bc3bfbe82838ca9f/onsale',
  },
  ABOUT: {
    title: 'ABOUT',
    url: 'https://sync-network.gitbook.io',
  },
  STATS: {
    title: 'STATS',
    url: '/stats',
  },
};

export const FOOTER_LINKS = [
  {
    title: 'Links',
    items: [
      {
        url: 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB6ff96B8A8d214544Ca0dBc9B33f7AD6503eFD32',
        title: 'Uniswap',
      },
      {
        url: 'https://etherscan.io/address/0xB6ff96B8A8d214544Ca0dBc9B33f7AD6503eFD32',
        title: 'Etherscan Contract',
      },
      {
        url: 'https://blog.coinfabrik.com/cbond-smart-contract-audit/',
        title: 'Smart Contract Audit',
      },
      {
        url: 'https://app.rarible.com/collection/0xc6c11f32d3ccc3beaac68793bc3bfbe82838ca9f/collectibles',
        title: 'Rarible',
      },
      {
        url: 'https://opensea.io/collection/cbond',
        title: 'Opensea',
      },
      {
        url: 'https://app.rarible.com/collection/0xc6c11f32d3ccc3beaac68793bc3bfbe82838ca9f/collectibles',
        title: 'Uniswap SYNC Liquidity',
      },
      {
        url: 'https://uniswap.info/token/0xB6ff96B8A8d214544Ca0dBc9B33f7AD6503eFD32',
        title: 'SYNC Uniswap Chart',
      },
    ],
  },
  {
    title: 'More',
    items: [
      {
        url: 'https://www.redbubble.com/people/SYNC-Network/shop',
        title: 'Merch - Shirts Stickers Prints',
      },
      {
        url: 'https://www.youtube.com/watch?v=QvabpLPo2hU',
        title: 'Explainer Video',
      },
      {
        url: 'https://github.com/', // TODO: check later
        title: 'Open Source',
      },
      {
        url: 'https://pdfhost.io/v/eJxdO5.YU_SYNC_NETWORK_Whitepaper_v20.pdf',
        title: 'Whitepaper',
      },
      {
        url: 'https://medium.com/sync-network',
        title: 'Blog',
      },
    ],
  },
  {
    title: 'Charts',
    items: [
      {
        url: 'https://www.dextools.io/app/uniswap/pair-explorer/0xfb2f545a9ad62f38fe600e24f75ecd790d30a7ba',
        title: 'DEXTools',
      },
      {
        url: 'https://uniswap.info/token/0xB6ff96B8A8d214544Ca0dBc9B33f7AD6503eFD32',
        title: 'Uniswap Chart',
      },
      {
        url: 'https://www.coingecko.com/en/coins/sync-network',
        title: 'Coin Gecko',
      },
      {
        url: 'https://coinmarketcap.com/currencies/sync-network/',
        title: 'Coin Market Cap',
      },
      {
        url: 'https://coinpaprika.com/coin/sync-sync-network/',
        title: 'Coin Paprika',
      },
      {
        url: 'https://www.livecoinwatch.com/price/SyncNetwork-SYNC',
        title: 'Live Coin Watch',
      },
      {
        url: 'https://blockfolio.com/coin/SYNC_2',
        title: 'Blockfolio',
      },
    ],
  },
  {
    title: 'Connect',
    items: [
      {
        url: 'https://discord.gg/JbSMb34',
        title: 'Discord',
      },
      {
        url: 'https://twitter.com/SYNCTOKEN',
        title: 'Twitter',
      },
      {
        url: 'https://www.reddit.com/r/SyncNetworkOfficial/',
        title: 'Reddit',
      },
      {
        url: 'https://medium.com/sync-network',
        title: 'Medium',
      },
      {
        url: 'https://www.youtube.com/results?search_query=SYNC+Network',
        title: 'SYNC Netowrk on YouTube',
      },
      {
        url: 'https://www.youtube.com/watch?v=XMcGHTiUlWs',
        title: 'Altcoin Daily',
      },
    ],
  },
];

export default PAGES;
