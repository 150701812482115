export const CBondContractAddress = '0xC6c11F32D3ccC3BEaac68793bC3BFBe82838ca9F'; // '0xd4e4078ca3495DE5B1d4dB434BEbc5a986197782';
export const SyncContractAddress = '0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32';
export const WETHContractAddress = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
export const DAIContractAddress = '0x6b175474e89094c44da98b954eedeac495271d0f';
export const NFTFIContractAddress = '0x88341d1a8f672d2780c8dc725902aae72f143b0c';

export const pools = [
  [
    '0xfb2f545a9ad62f38fe600e24f75ecd790d30a7ba',
    'SYNC',
    'ETH',
    '0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc',
    'USDC',
    'ETH',
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0x43ae24960e5534731fc831386c07755a2dc33d47',
    'SNX',
    'ETH',
    '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f',
    'AAVE',
    'ETH',
    '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974',
    'LINK',
    'ETH',
    '0x514910771af9ca656af840dff83e8264ecf986ca',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0xd90a1ba0cbaaaabfdc6c814cdf1611306a26e1f8',
    'SWAP',
    'ETH',
    '0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0x37a0464f8f4c207b54821f3c799afd3d262aa944',
    'DEXT',
    'ETH',
    '0x26ce25148832c04f3d7f26f32478a9fe55197166',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0x478893fcbfffc3283fece2a216229e1c34093980',
    'PAINT',
    'ETH',
    '0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0xc8e78ad2573f5e16a286443aea2a6f1ba0c06b96',
    'AAPX',
    'ETH',
    '0xbfd815347d024f449886c171f78fa5b8e6790811',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0x3041cbd36888becc7bbcbc0045e3b1f144466f5f',
    'USDT',
    'USDC',
    '0xdac17f958d2ee523a2206206994597c13d831ec7',
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  ],
  [
    '0xae461ca67b15dc8dc81ce7615e0320da1a9ab8d5',
    'USDC',
    'DAI',
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    '0x6b175474e89094c44da98b954eedeac495271d0f',
  ],
  [
    '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852',
    'USDT',
    'ETH',
    '0xdac17f958d2ee523a2206206994597c13d831ec7',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11',
    'DAI',
    'ETH',
    '0x6b175474e89094c44da98b954eedeac495271d0f',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0xbb2b8038a1640196fbe3e38816f3e67cba72d940',
    'WBTC',
    'ETH',
    '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  [
    '0x004375dff511095cc5a197a54140a24efef3a416',
    'WBTC',
    'USDC',
    '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  ],
  [
    '0x816579230a4c61670eba15486c8357bf87ec307e',
    'xBTC',
    'ETH',
    '0xecbf566944250dde88322581024e611419715f7a',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  //["0x767055e2a9f15783b1ec5ef134a89acf3165332f","USDC","EURS", "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", "0xdb25f211ab05b1c97d595516f45794528a807ad8"],
];

export const cryptoMap = {
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': 'wETH',
  '0x6b175474e89094c44da98b954eedeac495271d0f': 'DAI',
};

export const cryptoMapReverse = {
  wETH: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  DAI: '0x6b175474e89094c44da98b954eedeac495271d0f',
};
