
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles(theme => ({
  root: {
    width: 28,
    height: 20,
    color: theme.palette.text.primary
  }
}));

const EducatorsIcon = ({ className, viewBox, color, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox={viewBox || "0 0 28 20"} {...rest} className={clsx(classes.root, className)}>
      <g id="Icons"  >
        <g id="Icons" transform="translate(-507.000000, -318.000000)">
          <g id="Group-18" transform="translate(507.000000, 318.000000)">
            <g id="Group-12">
              <g id="black-male-user-symbol" transform="translate(0.000000, 4.166667)">
                <path d="M9.01618726,9.19344815 L8.98042929,9.23635772 C7.80312895,10.6489208 6.23495699,11.4272116 4.56567629,11.4272116 C2.89614899,11.4272116 1.32822364,10.6491675 0.150676689,9.23635772 L0.115165325,9.19344815 L0.123549953,9.13845486 C0.350921323,7.65536222 1.12575955,6.46400529 2.30527936,5.78312421 L2.38912563,5.7347893 L2.45595604,5.8048256 C3.01304056,6.38928346 3.76223169,6.71085859 4.56542969,6.71085859 C5.36862768,6.71085859 6.11781881,6.38903685 6.67490333,5.8048256 L6.74173374,5.7347893 L6.82558002,5.78312421 C8.00509983,6.46400529 8.77993805,7.65536222 9.00755603,9.13845486 L9.01618726,9.19344815 Z M4.56567629,5.2993312 C6.02657434,5.2993312 7.21521859,4.11068695 7.21521859,2.6497889 C7.21521859,1.18889086 6.02657434,0 4.56567629,0 C3.10477825,0 1.916134,1.18889086 1.916134,2.6495423 C1.916134,4.11019373 3.10477825,5.2993312 4.56567629,5.2993312 Z" id="Shape"></path>
              </g>
              <g id="black-male-user-symbol" transform="translate(18.333333, 4.166667)">
                <path d="M9.01618726,9.19344815 L8.98042929,9.23635772 C7.80312895,10.6489208 6.23495699,11.4272116 4.56567629,11.4272116 C2.89614899,11.4272116 1.32822364,10.6491675 0.150676689,9.23635772 L0.115165325,9.19344815 L0.123549953,9.13845486 C0.350921323,7.65536222 1.12575955,6.46400529 2.30527936,5.78312421 L2.38912563,5.7347893 L2.45595604,5.8048256 C3.01304056,6.38928346 3.76223169,6.71085859 4.56542969,6.71085859 C5.36862768,6.71085859 6.11781881,6.38903685 6.67490333,5.8048256 L6.74173374,5.7347893 L6.82558002,5.78312421 C8.00509983,6.46400529 8.77993805,7.65536222 9.00755603,9.13845486 L9.01618726,9.19344815 Z M4.56567629,5.2993312 C6.02657434,5.2993312 7.21521859,4.11068695 7.21521859,2.6497889 C7.21521859,1.18889086 6.02657434,0 4.56567629,0 C3.10477825,0 1.916134,1.18889086 1.916134,2.6495423 C1.916134,4.11019373 3.10477825,5.2993312 4.56567629,5.2993312 Z" id="Shape"></path>
              </g>
              <g id="black-male-user-symbol" transform="translate(5.833333, 0.000000)">
                <path d="M15.456321,15.7601968 L15.3950216,15.8337561 C13.3767925,18.2552929 10.6884977,19.5895055 7.82687365,19.5895055 C4.96482684,19.5895055 2.27695482,18.2557156 0.258302895,15.8337561 L0.197426272,15.7601968 L0.211799919,15.6659226 C0.60157941,13.1234781 1.92987351,11.0811519 3.95190747,9.91392722 L4.09564394,9.83106737 L4.21021036,9.9511296 C5.16521239,10.9530574 6.44954004,11.504329 7.82645089,11.504329 C9.20336174,11.504329 10.4876894,10.9526346 11.4426914,9.9511296 L11.5572578,9.83106737 L11.7009943,9.91392722 C13.7230283,11.0811519 15.0513224,13.1234781 15.4415246,15.6659226 L15.456321,15.7601968 Z M7.82687365,9.08456778 C10.3312703,9.08456778 12.3689462,7.04689191 12.3689462,4.54249527 C12.3689462,2.03809862 10.3312703,0 7.82687365,0 C5.322477,0 3.28480114,2.03809862 3.28480114,4.54207251 C3.28480114,7.0460464 5.322477,9.08456778 7.82687365,9.08456778 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
};

export default EducatorsIcon;


