
import { createContext } from 'react';
const AppContext = createContext({
  themeType: 'dark',
  themeChange: () => {},
  isWalletModalOpened: false,
  setIsWalletModalOpened: () => {}
});

export {
  AppContext
};