
import SvgIcon from '@material-ui/core/SvgIcon';

const MoonIcon = props => (
  <SvgIcon {...props}>
    <path d="M10.0151655,19.9569444 C13.8471232,19.9970486 17.347454,17.8019097 18.9615809,14.3467014 C17.9457445,14.7873264 16.8455607,15.003125 15.7375184,14.9791667 C11.3940625,14.9743056 7.87417277,11.475 7.86928311,7.15694444 C7.91276656,4.23611111 9.53387865,1.56440973 12.1123254,0.163541689 C11.4169393,0.0755208444 10.7161397,0.0361111111 10.0151655,0.0458333333 C4.4843842,0.0458333333 0.00104779412,4.50312498 0.00104779412,10.0013889 C0.00104779412,15.4998264 4.4843842,19.9569444 10.0151655,19.9569444 Z" id="Path"></path>
  </SvgIcon>
);

export default MoonIcon;
