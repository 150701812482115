import { useRouter } from 'next/router';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Web3Status from 'components/Web3Status';
import MenuPanelListItem from './MenuPanelItem';

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2),
  },
}));

const MenuPanel = ({ title, menuItems, onItemClick }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>{title}</Typography>
      <List>
        {menuItems.map((menuItem, index) => (
          <MenuPanelListItem
            key={index}
            selected={false}
            menuItem={menuItem}
            onClick={onItemClick}
          />
        ))}
      </List>
      <Box display='flex'>
        <Web3Status />
      </Box>
    </>
  );
};

export default MenuPanel;
