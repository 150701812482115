import Web3 from 'web3';
import BN from 'bn.js';

import {
  CBondContractAddress,
  DAIContractAddress,
  WETHContractAddress,
  NFTFIContractAddress,
  cryptoMap,
  pools,
} from 'config/contracts';
import { getNonce } from 'utils/nonce';
import {
  ADMIN_FEE_IN_BASIS_POINTS,
  BIG_NUBMER_STRING,
  COUNTS_CONFIRMATIONS_REQUIRED,
} from 'config/settings';
import { saveOffer, getOffer, getAssetListings } from 'services/nftfi';

const cbondAbi = require('config/abi/cbond.json');
const syncAbi = require('config/abi/sync.json');
const daiAbi = require('config/abi/dai.json');
const wethAbi = require('config/abi/weth.json');
const nftfiAbi = require('config/abi/nftfi.json');

let web3;

if (typeof window !== 'undefined' && window?.ethereum !== undefined) {
  web3 = new Web3(window.ethereum);
} else {
  web3 = new Web3.providers.HttpProvider('https://mainnet.infura.io/');
}

export const getNetworkType = async () => {
  const userNetwork = await web3.eth.net.getNetworkType();
  return userNetwork;
};

export const getAssets = async address => {
  const cBondContract = new web3.eth.Contract(cbondAbi, CBondContractAddress);

  try {
    const countOfCBonds = await cBondContract.methods.balanceOf(address).call();

    const cBondList = [];
    for (let i = 0; i < countOfCBonds; i++) {
      const tokenId = await cBondContract.methods.tokenOfOwnerByIndex(address, i).call();
      const cBond = await cBondContract.methods.putTogetherMetadataString(tokenId).call();

      const params = new URLSearchParams(cBond);
      const lpt = params.get('lAddr');
      let poolId = pools.findIndex(pool => pool[0].toLowerCase().includes(lpt.toLowerCase()));
      if (poolId < 0) {
        poolId = 0;
      }
      const bondedSYNC = (web3.utils.fromWei(params.get('syncAmount'), 'ether') * 1).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      );
      const startTime = params.get('startTime');
      const ttermLength = params.get('termLength');

      const tendOfTerm = Number(startTime) + Number(ttermLength);
      const nowTime = new Date().getTime() / 1000;
      const tseconds = Number(tendOfTerm) - nowTime;
      const days = Math.floor(ttermLength / 24 / 60 / 60).toFixed(0);

      cBondList.push({
        tokenId,
        lpt,
        poolId,
        bondedSYNC,
        days,
        startTime: Number(startTime),
        termLength: Number(ttermLength),
        endOfTerm: tendOfTerm,
      });
    }
    return cBondList;
  } catch (err) {
    console.log(err);
  }
};

// TODO: check later
export const matureCryptoBond = async tokenId => {
  const cBondContract = new web3.eth.Contract(cbondAbi, CBondContractAddress);

  if (tokenId > -1) {
    const accounts = await web3.eth.getAccounts();
    const result = await cBondContract.methods.matureCBOND(tokenId).send({
      from: accounts[0],
    });
    console.log('matureCryptoBond ==>', result);
  }
};

// TODO: check later
export const transferCryptoBond = async (fromAddress, toAddress, tokenId) => {
  const cBondContract = new web3.eth.Contract(cbondAbi, CBondContractAddress);
  const result = await cBondContract.methods.transferFrom(fromAddress, toAddress, tokenId).send({
    from: fromAddress,
  });
  console.log('transferCryptoBond ==>', result);
  return result;
};

export const isGranted = async (sender, crypto, amount = 50000) => {
  // TODO: compare with 50000 for convenience
  const erc20Contract =
    crypto === 'WETH' || crypto === 'wETH'
      ? new web3.eth.Contract(wethAbi, WETHContractAddress)
      : crypto === 'DAI'
      ? new web3.eth.Contract(daiAbi, DAIContractAddress)
      : null;

  const grantedAmount = await erc20Contract.methods.allowance(sender, NFTFIContractAddress).call();

  return new BN(grantedAmount).gte(new BN(amount));
};

/**
 * Checks if NFTfi contract is approved to manage transactions for particular ERC20
 *
 * @param {String} _erc20Address The ERC20 to check approval for
 * @param {String} _lender The user's Ethereum account to check approval for
 * @return {Boolean} Returns true is a user has already approved the NFT category
 */
export const getERC20Allowance = async (_erc20Address, _lender) => {
  console.log('_erc20Address ==>', _erc20Address);
  const crypto = cryptoMap[_erc20Address.toLowerCase()];

  const erc20Contract =
    crypto === 'WETH' || crypto === 'wETH'
      ? new web3.eth.Contract(wethAbi, WETHContractAddress)
      : crypto === 'DAI'
      ? new web3.eth.Contract(daiAbi, DAIContractAddress)
      : null;

  if (!erc20Contract) return null;

  const allowance = await erc20Contract.methods
    .allowance(_lender, NFTFIContractAddress)
    .call({ from: NFTFIContractAddress });
  return new BN(allowance);
};

export const getBalance = async (address, crypto) => {
  const erc20Contract =
    crypto === 'WETH' || crypto === 'wETH'
      ? new web3.eth.Contract(wethAbi, WETHContractAddress)
      : crypto === 'DAI'
      ? new web3.eth.Contract(daiAbi, DAIContractAddress)
      : crypto === 'SYNC'
      ? new web3.eth.Contract(syncAbi, SYNCContractAddress)
      : null;
  if (!erc20Contract) {
    return undefined;
  }

  const balance = await erc20Contract.methods.balanceOf(address).call();
  return balance;
};

export const getFormatedBalance = balance => {
  if (web3?.utils) {
    return (web3.utils.fromWei(balance || '0', 'ether') * 1).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    });
  }
  return (balance / 1e18).toFixed(2);
};

export const formatCurrency = (num, ticker) => {
  const decimals = ticker === 'DAI' ? 0 : 4;

  if (!num) return '0';

  if (web3?.utils) {
    return (
      web3.utils
        .fromWei(num.toLocaleString('fullwide', { useGrouping: false }), 'ether')
        .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: decimals }) +
      ' ' +
      ticker
    );
  }
  return Number(0).toFixed(2) + ' ' + ticker;
};

export const getLoanDetails = loan => {
  if (!loan) return null;
  loan.loanPrincipalAmount = web3.utils.fromWei(
    loan.loanPrincipalAmount.toLocaleString('fullwide', { useGrouping: false }),
    'ether',
  );
  loan.maximumRepaymentAmount = web3.utils.fromWei(
    loan.maximumRepaymentAmount.toLocaleString('fullwide', { useGrouping: false }),
    'ether',
  );

  return loan;
};

export const approveLend = async (
  sender,
  amount = BIG_NUBMER_STRING,
  { crypto, _onReceipt, _onBlockConfirmed, _onConfirm, _onError } = {},
) => {
  const contract =
    crypto === 'WETH' || crypto === 'wETH'
      ? new web3.eth.Contract(wethAbi, WETHContractAddress)
      : crypto === 'DAI'
      ? new web3.eth.Contract(daiAbi, DAIContractAddress)
      : null;

  const result = await contract.methods.approve(NFTFIContractAddress, amount);
  if (!result) return;

  result
    .send({ from: sender })
    .on('error', error => {
      if (_onError) _onError(error);
    })
    .on('receipt', receipt => {
      if (_onReceipt) _onReceipt(receipt);
    });
  return result;
};

export async function signListingMessage({
  _nftCollateralId,
  _borrower,
  _onSigned,
  _onError,
  _desiredLoanPrincipalAmount,
  _desiredLoanDuration,
  _desiredLoanCurrency,
} = {}) {
  const nonce = await getNonce(web3);

  const chainId = await web3.eth.getChainId();

  const hash = web3.utils.soliditySha3(
    { type: 'uint256', value: _nftCollateralId }, //_nftCollateralId
    { type: 'uint256', value: nonce }, //_borrowerNonce
    { type: 'address', value: CBondContractAddress }, //_nftCollateralContract
    { type: 'address', value: _borrower }, //_borrower
    { type: 'uint256', value: chainId },
  );

  try {
    const result = await web3.eth.personal.sign(hash, _borrower);
    const listing = {
      nonce: nonce,
      nftCollateralId: _nftCollateralId,
      nftCollateralContract: CBondContractAddress,
      borrower: _borrower,
      signedMessage: result,
      desiredLoanPrincipalAmount: _desiredLoanPrincipalAmount || null,
      desiredLoanDuration: _desiredLoanDuration || null,
      desiredLoanCurrency: _desiredLoanCurrency || null,
    };
    if (_onSigned) _onSigned(result);
    return listing;
  } catch (error) {
    if (_onError) _onError(error);
    console.log(error);
  }
}

/**
 *
 *
 * @param {*} _loanPrincipalAmount
 * @param {*} _maximumRepaymentAmount
 * @param {*} _nftCollateralId
 * @param {*} _loanDuration
 * @param {*} _nftCollateralContract
 * @param {*} _loanERC20Denomination
 * @param {*} _lender
 * @param {*} _callback
 */
export async function signOfferMessage({
  _loanPrincipalAmount,
  _maximumRepaymentAmount,
  _nftCollateralId,
  _loanDuration,
  _lender,
  _borrower,
  _onSigned,
  _onError,
  _onSuccess,
  crypto = 'WETH',
}) {
  const lenderNonce = getNonce(web3).toString();

  //Always do a fixed repayment amount loan
  const _loanInterestRateForDurationInBasisPoints = new BN(0).notn(32).toString();
  const _interestIsProRated = false;
  const _adminFeeInBasisPoints = ADMIN_FEE_IN_BASIS_POINTS;
  const _loanERC20Denomination =
    crypto === 'WETH' || crypto === 'wETH'
      ? WETHContractAddress
      : crypto === 'DAI'
      ? DAIContractAddress
      : null;
  if (!_loanERC20Denomination) {
    return;
  }

  const chainId = await web3.eth.getChainId();

  const loanPrincipalAmount = web3.utils.toWei(_loanPrincipalAmount.toString(), 'ether');
  const maximumRepaymentAmount = web3.utils.toWei(_maximumRepaymentAmount.toString(), 'ether');

  const hash = await web3.utils.soliditySha3(
    { type: 'uint256', value: loanPrincipalAmount.toString() },
    { type: 'uint256', value: maximumRepaymentAmount.toString() },
    { type: 'uint256', value: _nftCollateralId },
    { type: 'uint256', value: _loanDuration },
    { type: 'uint256', value: _loanInterestRateForDurationInBasisPoints },
    { type: 'uint256', value: _adminFeeInBasisPoints },
    { type: 'uint256', value: lenderNonce },
    { type: 'address', value: CBondContractAddress },
    { type: 'address', value: _loanERC20Denomination },
    { type: 'address', value: _lender },
    { type: 'bool', value: _interestIsProRated },
    { type: 'uint256', value: chainId },
  );

  web3.eth.personal.sign(hash, _lender, function (err, result) {
    if (!err) {
      const offer = {
        loanPrincipalAmount: loanPrincipalAmount,
        maximumRepaymentAmount: maximumRepaymentAmount,
        nftCollateralId: _nftCollateralId,
        loanDuration: _loanDuration,
        loanInterestRateForDurationInBasisPoints:
          _loanInterestRateForDurationInBasisPoints.toString(),
        adminFeeInBasisPoints: _adminFeeInBasisPoints,
        lenderNonce: lenderNonce,
        nftCollateralContract: CBondContractAddress,
        loanERC20Denomination: _loanERC20Denomination,
        lender: _lender,
        borrower: _borrower,
        interestIsProRated: _interestIsProRated,
        signedMessage: result,
      };

      if (_onSigned) {
        _onSigned(result);
      }

      saveOffer(offer, _onSuccess);
    } else {
      console.log(err);
      if (_onError) {
        _onError(err);
      }
    }
  });
}

export async function acceptOffer({
  _nftCollateralId,
  _lender,
  _onSuccess,
  _onConfirm,
  _onError,
  _onReceipt,
  _onBlockConfirmed,
}) {
  console.log('acceptOffer called', _nftCollateralId, _lender);
  const offer = await getOffer(_nftCollateralId, _lender);
  const listings = await getAssetListings(_nftCollateralId);
  const listing = listings[0];

  const nftContract = new web3.eth.Contract(nftfiAbi, NFTFIContractAddress, {
    from: listing.borrower,
  });

  await nftContract.methods
    .beginLoan(
      offer.loanPrincipalAmount.toLocaleString('fullwide', { useGrouping: false }),
      offer.maximumRepaymentAmount.toLocaleString('fullwide', { useGrouping: false }),
      offer.nftCollateralId,
      offer.loanDuration,
      new BN(offer.loanInterestRateForDurationInBasisPoints),
      offer.adminFeeInBasisPoints,
      [listing.nonce, offer.lenderNonce],
      offer.nftCollateralContract,
      offer.loanERC20Denomination,
      offer.lender,
      listing.signedMessage,
      offer.signedMessage,
    )
    .send({ from: listing.borrower })
    .on('confirmation', (confirmationNumber, receipt) => {
      console.log('beginLoan (confirmationNumber, receipt) ==>', confirmationNumber, receipt);
      if (_onBlockConfirmed && confirmationNumber <= COUNTS_CONFIRMATIONS_REQUIRED) {
        _onBlockConfirmed(confirmationNumber);
      }
      if (confirmationNumber === COUNTS_CONFIRMATIONS_REQUIRED) {
        _onConfirm(receipt);
        _onSuccess();
      }
    })
    .on('receipt', receipt => {
      console.log('beginLoan receipt ==>', receipt);
      if (_onReceipt) _onReceipt(receipt);
    })
    .on('error', error => {
      console.error('beginLoan ==>', error);
      if (_onError) _onError(error);
    });
}

/**
 * Approves NFTfi to spend all of an accounts ERC20 tokens
 *
 * @param {Object} _web3
 * @param {String} _loanId ID of the loan to liquidate
 * @param {String} _lender The user's Ethereum account
 * @param {function} _onSuccess Callback function to update the UI state on successful completion of the transaction
 * @param {function} _onConfirm Callback function to update UI state on start of transaction confirmation
 * @param {function} _onError Callback function to update UI state on error
 * @param {function} _onReceipt Callback function to update UI state on receipt of 8 confirmations of the transaction meaning it won't be reverted
 * @param {function} _onBlockConfirmed Callback function to update UI state on receipt of each confirmed block in the transaction
 */
export async function liquidateOverdueLoan({
  _loanId,
  _lender,
  _onSuccess,
  _onConfirm,
  _onError,
  _onReceipt,
  _onBlockConfirmed,
}) {
  const nftContract = new web3.eth.Contract(nftfiAbi, NFTFIContractAddress, {
    from: _lender,
  });

  await nftContract.methods
    .liquidateOverdueLoan(_loanId)
    .send({ from: _lender })
    .on('confirmation', (confirmationNumber, receipt) => {
      console.log(
        'liquidateOverdueLoan (confirmationNumber, receipt) ==>',
        confirmationNumber,
        receipt,
      );
      if (_onConfirm && confirmationNumber === parseInt(COUNTS_CONFIRMATIONS_REQUIRED)) {
        _onConfirm(receipt);
        if (_onSuccess) _onSuccess();
      }
      if (_onBlockConfirmed && confirmationNumber <= parseInt(COUNTS_CONFIRMATIONS_REQUIRED))
        _onBlockConfirmed(confirmationNumber);
    })
    .on('receipt', receipt => {
      console.log('liquidateOverdueLoan receipt ==>', receipt);
      if (_onReceipt) _onReceipt(receipt);
    })
    .on('error', error => {
      console.log('liquidateOverdueLoan error ==>', error);
      if (_onError) _onError(error);
    });
}

/**
 *
 *
 * @param {*} _loanId
 */
export async function payBackLoan({
  _loanId,
  _borrower,
  _onSuccess,
  _onConfirm,
  _onError,
  _onReceipt,
  _onBlockConfirmed,
}) {
  const nftContract = new web3.eth.Contract(nftfiAbi, NFTFIContractAddress, {
    from: _borrower,
  });

  await nftContract.methods
    .payBackLoan(_loanId)
    .send({ from: _borrower })
    .on('confirmation', (confirmationNumber, receipt) => {
      console.log(
        'payBackLoan TEST 2: (confirmationNumber, receipt) ==>',
        confirmationNumber,
        receipt,
      );
      if (_onBlockConfirmed && confirmationNumber <= COUNTS_CONFIRMATIONS_REQUIRED) {
        _onBlockConfirmed(confirmationNumber);
      }
      if (confirmationNumber === COUNTS_CONFIRMATIONS_REQUIRED) {
        _onConfirm(receipt);
        _onSuccess();
      }
    })
    .on('receipt', receipt => {
      console.log('payBackLoan TEST 3: receipt ==>', confirmationNumber, receipt);
      if (_onReceipt) _onReceipt(receipt);
    })
    .on('error', error => {
      console.log('payBackLoan TEST 4: error ==>', error);
      if (_onError) _onError(error);
    });
}

/**
 * Checks if NFTfi contract is approved to manage transactions for particular NFT category
 *
 * @param {String} _borrower The user's Ethereum account to check approval for
 * @return {Boolean} Returns true is a user has already approved the NFT category
 */
export async function isApprovedNftCategory({ _borrower }) {
  //load nft contract using ERC721 ABI
  const cbondContract = new web3.eth.Contract(cbondAbi, CBondContractAddress, {
    from: _borrower,
  });

  const isApproved = await cbondContract.methods
    .isApprovedForAll(_borrower, NFTFIContractAddress)
    .call({ from: CBondContractAddress });

  console.log('isApprovedNftCategory: isApproved ===>', isApproved);
  return isApproved;
}

/**
 * If NFTfi contract is not approved to manage transactions for particular NFT category sets approval
 * Calls signListingMessage
 *
 * @param {String} _nftCollateralId The NFT id to sign a listing for
 * @param {String} _borrower The user's Ethereum account to sign a listing for
 * @param {function} _onConfirm Callback function to update UI state on start of transaction confirmation
 * @param {function} _onError Callback function to update UI state on error
 * @param {function} _onReceipt Callback function to update UI state on receipt of 8 confirmations of the transaction meaning it won't be reverted
 * @param {function} _onBlockConfirmed Callback function to update UI state on receipt of each confirmed block in the transaction
 */

export async function approveERC721IfNotApproved({
  _borrower,
  _onConfirm,
  _onError,
  _onReceipt,
  _onBlockConfirmed,
}) {
  const cbondContract = new web3.eth.Contract(cbondAbi, CBondContractAddress, {
    from: _borrower,
  });

  const isApproved = await cbondContract.methods
    .isApprovedForAll(_borrower, NFTFIContractAddress)
    .call({ from: CBondContractAddress });

  if (!isApproved) {
    await cbondContract.methods
      .setApprovalForAll(NFTFIContractAddress, true)
      .send({ from: _borrower })
      .on('confirmation', (confirmationNumber, receipt) => {
        if (_onConfirm && confirmationNumber === COUNTS_CONFIRMATIONS_REQUIRED) _onConfirm(receipt);
        if (_onBlockConfirmed && confirmationNumber <= COUNTS_CONFIRMATIONS_REQUIRED)
          _onBlockConfirmed(confirmationNumber);
      })
      .on('receipt', receipt => {
        if (_onReceipt) _onReceipt(receipt);
      })
      .on('error', error => {
        if (_onError) _onError(error);
      });
  }
  return isApproved;
}
