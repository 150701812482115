import { CBondContractAddress, WETHContractAddress } from 'config/contracts';
import { COUNTS_ON_PAGE } from 'config/settings';

import { NftfiApiProxy } from './nftfiApiProxy';

let api = null;
if (typeof window !== 'undefined') {
  api = new NftfiApiProxy();
}

const page = {
  limit: COUNTS_ON_PAGE,
  skip: 0,
  sort: null,
};

/**
 * Gets a list of NFT's and their metadata that are listed, under offer, in escrow or defaulted on the NFTfi platform
 *
 * @param {string} account The Ethereum account to list NFT's that are not owned by this user.
 * @param {string} assetContractAddress The NFT contract address to request only assets of this type
 * @param {string} status The status of the NFT aseset to request only assets of this status.
 * @return {array of objects} Returns a JSON array of NFT metadata that adheres to the following metadata standards https://docs.opensea.io/docs/metadata-standards
 */
export const getManagedAssets = async ({ account, status, offset }) => {
  const paging = {
    limit: COUNTS_ON_PAGE,
    skip: offset,
    sort: null,
  };

  const filters = {
    nftCollateralContract: [CBondContractAddress.toLowerCase(), CBondContractAddress],
  };

  const apiResponse = await api.get(`/listings`, {
    headers: {
      'X-Filters': JSON.stringify(filters),
      'X-Paging': JSON.stringify(paging),
    },
  });

  return {
    data: apiResponse.data,
    total: apiResponse.headers['x-total'],
  };
};

export const getSingleAssetByNftfi = async (tokenId, account) => {
  const result = await api.get(`/asset/${CBondContractAddress}/${tokenId}`);
  const asset = result.data;

  const listings = await getAssetListings(tokenId, account);

  if (listings?.length >= 1) {
    asset.status = 'listed';
    asset.desiredLoanDuration = listings[0].desiredLoanDuration;
    asset.desiredLoanValue = listings[0].desiredLoanPrincipalAmount;
    asset.desiredLoanCurrency = listings[0].desiredLoanCurrency || WETHContractAddress;
  } else {
    asset.status = 'unlisted';
  }

  const loan = await getLatestLoanForAsset(tokenId);
  if (loan && loan.status) {
    asset.status = loan.status;
  }
  return asset;
};

export const getAssetListings = async (tokenId, account) => {
  const result = await api.get(`/listings/${CBondContractAddress}/${tokenId}`);

  let listings = result.data;
  // if (account) {
  //   listings = listings.filter(listing => listing.borrower.toLowerCase() !== account.toLowerCase());
  // }
  return listings;
};

export const getListingsByAccount = async account => {
  const result = await api.get(`/listings/${account}`);

  let listings = result.data;
  return listings;
};

export const removeCollateral = async tokenId => {
  const result = await api.delete(`/listings/${CBondContractAddress}/${tokenId}`);
  return result;
};

export const addListing = async data => {
  const result = await api.post(`/listings`, data);
  return result;
};

export async function saveListingTerms(_listing) {
  const result = await api.put(`/listings`, _listing);
  return result;
}

export const getBorrowerOffersByAddress = async address => {
  const result = await api.get(`/offers/borrower/${address}`);
  return result.data.filter(
    asset => asset.nftCollateralContract?.toLowerCase() === CBondContractAddress.toLowerCase(),
  );
};

export const getLenderOffersByAddress = async address => {
  const result = await api.get(`/offers/lender/${address}`);
  return result.data.filter(
    asset => asset.nftCollateralContract?.toLowerCase() === CBondContractAddress.toLowerCase(),
  );
};

export const getAssetOffers = async tokenId => {
  const result = await api.get(`/offers/${CBondContractAddress}/${tokenId}`);
  // https://nftfi.com/app/asset/0xc2c747e0f7004f9e8817db2ca4997657a7746928/15331

  // const result = await api.get(`/offers/${CBondContractAddress}/1492`);
  // const result = await api.get(`/offers/0xfbeef911dc5821886e1dda71586d90ed28174b7d/22066`);
  return result.data;
};

export const saveOffer = async (offer, _onSuccess) => {
  const result = await api.post(`/offers`, offer);
  _onSuccess && _onSuccess(offer);
};

export const deleteOffer = async ({ _nftCollateralId, _lender, _onSuccess, _onError }) => {
  const result = await api.delete(`/offers/${CBondContractAddress}/${_nftCollateralId}/${_lender}`);
  return result;
};

export const validateAccount = async address => {
  const result = await api.get(`/accounts/${address}/validate`);
  return result.data;
};

export async function getOffer(_nftCollateralId, _lender) {
  const { data: offer } = await api
    .get(`/offers/${CBondContractAddress}/${_nftCollateralId}/${_lender}`)
    .catch(function (error) {
      console.log(error);
    });

  return offer[0];
}

export async function getLatestLoanForAsset(tokenId) {
  const apiResponse = await api.get(`/loans/${CBondContractAddress}/${tokenId}/latest`);
  return apiResponse.data;
}

export async function getLoansForBorrower(_account) {
  // 0x8AB0b38B5331ADAe0EDfB713c714521964C5bCCC
  // 0x5ed7d8e2089b2b0e15439735b937cec5f0ae811b
  // 0x83615aF49DbEde4709ce9f00771bD7B077453feA
  const response = await api.get(`/loans/${_account}`);
  return response.data;
}

export async function getLoansForLender(_account) {
  const response = await api.get(`/loans/lender/${_account}`);
  return response.data;
}

export async function getStats() {
  const response = await api.get(`/stats/contract/${CBondContractAddress}`);
  return response.data;
}

export async function getAccountStats(address) {
  const response = await api.get(`/stats/account/${address}`);
  return response.data;
}
