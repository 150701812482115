import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getNonce } from 'utils/nonce';
import { NFTFI_API } from 'config/settings';

const Web3 = require('web3');

let web3;

if (typeof window !== 'undefined' && window?.ethereum !== undefined) {
  web3 = new Web3(window.ethereum);
} else {
  web3 = new Web3.providers.HttpProvider('https://mainnet.infura.io/');
}

/**
 * Signs a message from the current account stating intention to list an item. Message signature and
 * listing parameters are stored in the NFTfi API after generation.
 *
 * @param {String} _nftCollateralId The NFT id to sign a listing for
 * @param {String} _nftCollateralContract The NFT category to sign a listing for
 * @param {String} _borrower The user's Ethereum account to sign a listing for
 * @param {function} _onSigned Callback function to update UI state on success
 * @param {function} _onError Callback function to update UI state on error
 */
async function signMessage(_message, _account, _onSigned, _onError) {
  let nonce = getNonce(web3).toString();

  const chainId = await web3.eth.getChainId();
  const messageToSign = `${_message}\r\n\r\nChainId : ${chainId}\r\nNonce : ${nonce})`;

  const res = await web3.eth.personal.sign(messageToSign, _account, function (err, result) {
    if (!err) {
      if (_onSigned)
        return _onSigned({
          message: _message,
          nonce,
          signedMessage: result,
        });
    } else {
      console.log(err);
      if (_onError) _onError(err);
    }
  });
  return {
    message: _message,
    nonce,
    signedMessage: res,
  };
}

class NftfiApiProxy {
  constructor(signInRequired, signInFailed) {
    const localSignInRequired = signInRequired;
    const localSignInFailed = signInFailed;

    this.axiosInstance = axios.create();
    const localAxiosRef = this.axiosInstance;

    const localRefreshLogicRef = this.refreshAuthLogic;
    if (!web3?.eth) return;
    web3.eth.getAccounts(function (error, accounts) {
      console.log('NftfiApiProxy getAccounts ==>', error, accounts);
      createAuthRefreshInterceptor(localAxiosRef, failedRequest => {
        return localRefreshLogicRef(
          failedRequest,
          accounts[0],
          localSignInRequired,
          localSignInFailed,
        );
      });

      // Use interceptor to inject the token to requests
      localAxiosRef.interceptors.request.use(request => {
        request.headers['Authorization'] = `Bearer ${localStorage.getItem('jwtToken')}`;
        return request;
      });
    });
  }

  async refreshAuthLogic(failedRequest, account, signInRequired, signInFailed) {
    function setToken(failedRequest, authResponse) {
      console.log('setToken failedRequest, authResponse ==>', failedRequest, authResponse);
      localStorage.setItem('token', authResponse.data.token);
      localStorage.setItem('jwtToken', authResponse.data.jwtToken);
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + authResponse.data.jwtToken;
    }

    async function signIn(failedRequest) {
      console.log('signIn signInRequired, failedRequest==>', signInRequired, failedRequest);
      let result;
      if (signInRequired) {
        signInRequired(true);

        //Sign in using ECDSA signature and update UI according to signature
        result = await signMessage(
          `Welcome to Sync!\r\n\r\nClick 'Sign' to sign in. No password needed!\r\n\r\n This message proves you own this wallet address : ${account}`,
          account,
          () => {},
          err => {
            signInFailed(err.message);
            console.error(err);
          },
        );
      } else {
        //Sign in using ECDSA signature
        result = await signMessage(
          `Welcome to Sync!\r\n\r\nClick 'Sign' to sign in. No password needed!\r\n\r\n This message proves you own this wallet address : ${account}`,
          account,
          () => {},
          err => console.error(err),
        );
      }

      const payload = { ...result, accountAddress: account };

      let authResponse;
      try {
        authResponse = await axios.post(`${NFTFI_API}/accounts/authenticate`, payload);
        if (signInRequired) {
          signInRequired(false);
        }
      } catch (error) {
        if (signInFailed) {
          signInFailed(error.message);
        }
      }

      setToken(failedRequest, authResponse);

      return Promise.resolve();
    }

    const token = localStorage.getItem('jwtToken');
    const refreshToken = localStorage.getItem('token');

    if (token) {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      };

      try {
        await axios
          .post(`${NFTFI_API}/accounts/refresh-token`, { refreshToken }, config)
          .then(tokenRefreshResponse => {
            // if token refresh worked set token
            this.setToken(failedRequest, tokenRefreshResponse);
            return Promise.resolve();
          });
      } catch {
        //if not then sign in
        return await signIn(failedRequest);
      }

      return Promise.resolve();
    } else return await signIn(failedRequest);
  }

  get(url, options) {
    return this.axiosInstance.get(`${NFTFI_API}${url}`, options);
  }

  put(url, payload) {
    return this.axiosInstance.put(`${NFTFI_API}${url}`, payload);
  }

  post(url, payload) {
    return this.axiosInstance.post(`${NFTFI_API}${url}`, payload);
  }

  delete(url) {
    return this.axiosInstance.delete(`${NFTFI_API}${url}`);
  }
}

export { NftfiApiProxy, signMessage };
